.App {
  text-align: center;
}


@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Liberation+Serif&display=swap');

.category-dropdown-container {
  position: absolute; /* Set to absolute for precise positioning */
  left: 25%; /* Position at 25% of the parent container's width */
  margin-top: 10px;
}

.category-dropdown {
  padding: 10px;
  border-radius: 5px;
  border: 2px solid #ddd;
  font-size: 16px;
  cursor: pointer;
}




.comments-sidebar {
  position: fixed;
  right: 0;
  top: 0;
  width: 350px; /* Adjust width as needed */
  height: 100%;
  background-color: #f7f7f7;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  padding: 20px;
  overflow-y: auto;
  z-index: 1000; /* Make sure it's above other content */
}

.comments-list {
  list-style: none;
  padding: 0;
}

.comment {
  background-color: #ffffff;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.comments-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  font-size: 24px;
  cursor: pointer;
}

.leave-comment-btn {
  padding: 10px 15px;
  margin-top: 20px; /* Adjust as needed */
  background-color: #007bff; /* A blue background */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.leave-comment-btn:hover {
  background-color: #0056b3; /* Darker shade on hover */
}

.comments-input {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.comments-submit-btn {
  padding: 10px 15px;
  margin-top: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.comments-submit-btn:hover {
  background-color: #0056b3;
}







.filters-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative; /* Set the position to relative for absolute positioning of children */
  margin-top: -5px; /* Adjust this value as needed to align with the search bar */
}



.logout-button {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #ff4d4d; /* Mild red background */
  color: white; /* White text */
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}




.new-prompt-btn {
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 10px 15px;
  background-color: #007bff; /* Example color, adjust as needed */
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1000; /* To ensure it's above other content */
}

.new-prompt-btn:hover {
  background-color: #0056b3; /* Darker shade for hover effect */
}


.new-prompt-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  font-size: 24px;
  cursor: pointer;
}

.new-prompt-sidebar {
  position: fixed;
  right: 0;
  top: 0;
  width: 350px; /* Adjust width as needed */
  height: 100%;
  background-color: #f7f7f7;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  padding: 20px;
  overflow-y: auto;
  z-index: 1000; /* Make sure it's above other content */
}

.new-prompt-form input[type="text"],
.new-prompt-form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

.new-prompt-form textarea {
  height: 150px; /* Adjustable based on needs */
  resize: vertical; /* Allow vertical resizing */
}

.new-prompt-form button[type="submit"]:hover {
  background-color: #218838; /* Darker shade for hover effect */
}

.new-prompt-form-actions {
  display: flex;
  justify-content: space-between;
}

.submit-new-prompt, .cancel-new-prompt {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.submit-new-prompt {
  background-color: #28a745; /* Example color */
  color: white;
}

.cancel-new-prompt {
  background-color: #dc3545; /* Example color */
  color: white;
}

.submit-new-prompt:hover {
  background-color: #218838; /* Darker shade for hover */
}

.cancel-new-prompt:hover {
  background-color: #c82333; /* Darker shade for hover */
}






.prompt-result {
  width: 100%;
  max-width: 1200px; /* Adjust width as needed */
  background: #f9f9f9;
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.prompt-title {
  font-size: 24px;
  margin-bottom: 15px;
}

.prompt-body {
  font-size: 16px;
  margin-bottom: 15px;
}

.prompt-actions {
  display: flex;
  align-items: center;
  justify-content: start;
}

.prompt-button {
  margin-right: 10px;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  background-color: #ddd;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.prompt-button.upvote {
  color: green;
}

.prompt-button.downvote {
  color: red;
}

.prompt-button:hover {
  background-color: #ccc;
}

.prompt-link {
  text-decoration: none;
  color: #007bff;
  padding: 10px 15px;
  transition: color 0.3s ease;
}

.prompt-link:hover {
  color: #0056b3;
}




.prompt-title-editable, .prompt-category-editable {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 24px; /* Same as .prompt-title */
}

.prompt-category-editable {
  font-size: 16px; /* Smaller font for category */
}

.prompt-body-editable {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px; /* Same as .prompt-body */
  height: 150px; /* Adjustable based on needs */
  resize: vertical; /* Allow vertical resizing */
}







.search-bar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative; /* Ensure this is relative to position children absolutely */
}

.search-input {
  width: 70%;
  padding: 10px;
  border: 2px solid #ddd;
  border-radius: 5px 0 0 5px;
  font-size: 16px;
}

.search-button {
  padding: 10px 20px;
  border: none;
  border-radius: 0 5px 5px 0;
  background-color: #007BFF;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.search-button:hover {
  background-color: #0056b3;
}




.search-results-container {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}




.sort-dropdown-container {
  position: absolute; /* Set to absolute for precise positioning */
  right: 25%; /* Position at 25% from the right of the parent container's width */
  margin-top: 10px;
}

.sort-dropdown {
  padding: 10px;
  border-radius: 5px;
  border: 2px solid #ddd;
  font-size: 16px;
  cursor: pointer;
}




.switch-view-button {
  background-color: purple;
  color: white;
  border: none;
  padding: 10px 15px;
  margin-left: 10px; /* Adjust as needed */
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;

  /* Adding a little shadow for depth */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.switch-view-button:hover {
  background-color: darkviolet; /* Slightly darker shade on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* More pronounced shadow on hover */
}





/* Add this to ensure the dropdowns don't overlap with the search bar on smaller screens */
@media (max-width: 768px) {
  .category-dropdown-container,
  .sort-dropdown-container {
      position: static;
      margin-bottom: 10px;
  }
  .filters-container {
      flex-direction: column;
  }
}